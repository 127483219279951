<template>
  <step-lesson-tabs :tabs="tabs"></step-lesson-tabs>
</template>

<script>
import StepLessonTabs from '@/components/training/StepLessonTabs'

export default {
  name: 'Module1Part4Lesson',
  components: { StepLessonTabs },
  data () {
    return {
      tabs: [
        {
          name: 'tab1',
          label: this.$t('module1.part4.lesson1.tab'),
          component: () => import('@/components/training/module1/part4/Module1Part4Lesson1')
        },
        {
          name: 'tab2',
          label: this.$t('module1.part4.lesson2.tab'),
          component: () => import('@/components/training/module1/part4/Module1Part4Lesson2')
        },
        {
          name: 'tab3',
          label: this.$t('module1.part4.lesson3.tab'),
          component: () => import('@/components/training/module1/part4/Module1Part4Lesson3')
        }
      ]
    }
  }
}
</script>
